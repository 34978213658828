<div *ngIf="visible" class="particle" id="particles-js">
  <div class="container">
    <div class="credits">
      <div class="headings">
        <h1>Episode I</h1>
        <h2>THE AWS JEDI</h2>
      </div>
      <p>
        Hello! My name is MD FAZLA RABBY. I am an AWS Certified Solutions Architect – Associate and a Full Stack
        Developer.
      </p>
      <br>
      <p>I have more than 2 years of experience working with AWS and overall 5 years of IT related experience. I am with
        The Force and The Force made me strong with AWS, Linux, Python, Docker, CICD, Java and many more ...
      </p>
      <button (click)='onClick()'>Click here to Learn more</button>
    </div>
  </div>
</div>
