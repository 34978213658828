import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var particlesJS: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'my-portfolio';
  visible = true;
  constructor(private router: Router) { }
  ngOnInit() {

    particlesJS.load('particles-js', '../assets/particles.json', () => {
      console.log('callback - particles.js config loaded');
    });
  }

  onClick() {
    console.log('clicked!');
    this.router.navigate(['intro']);
    this.visible = false;
  }
}
