<app-main-nav>

  <div class="intro-body">


    <div class="two">
      <h1> Technical skills</h1>
      <h2>
          <p>Amazon Web Services</p>
          <p>Linux</p>
          <p>Python</p>
          <p>Flask</p>
          <p>Git</p>
          <p>Docker Containers</p>
          <p>DevOps</p>
          <p>CICD</p>
          <p>Java</p>
          <p>Spring Framework</p>
          <p>Relational and non-relational Databases</p>
          <p>Angular</p>
          <p>Ionic</p>

      </h2>
    </div>
    <div class="one">
      <img src="../../assets/images/technical_skills.png" alt="">
    </div>

  </div>
</app-main-nav>
