<app-main-nav>
  <div class="intro-body">


    <div class="two">
      <h1> My Story</h1>
      <h2>I’m Md Fazla Rabby, an AWS Certified Solutions Architect – Associate and a Full Stack Developer.</h2>
      <h2>2 years of experience planning, designing implementing and maintaining system applications in AWS Cloud in
        Linux and Windows environments and overall 5 years of IT related experience. Experience working in Agile Scrum
        Software Development Life Cycle with respect to delivering Operations, Functional and Technical Specifications,
        Development, Testing, and Maintenance.
      </h2>
    </div>
    <div class="one">
      <img style="transform: scale(1.0);" src="../../assets/images/nobg2.png" alt="">
    </div>

  </div>
</app-main-nav>
