<app-main-nav>
  <div class="intro-body">

    <div class="one"><img src="../../assets/images/ProfilePic.png" alt=""></div>


    <div class="two">
      <h1> Hello I am Fazla</h1>
      <h2>An AWS Certified Solutions Architect – Associate and a Full Stack Developer</h2>
    </div>

  </div>
</app-main-nav>
