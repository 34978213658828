<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" *ngIf="isHandset$ | async" fixedInViewport="false"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar color="primary">Menu</mat-toolbar>
    <mat-nav-list >
      <a mat-list-item routerLink="/intro" routerLinkActive="active">Intro</a>
      <a mat-list-item routerLink="/about" routerLinkActive="active">About</a>
      <a mat-list-item routerLink="/services" routerLinkActive="active">Services</a>
      <a mat-list-item routerLink="/contact" routerLinkActive="active">Contact</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" mat-icon-button (click)="toggleSidenav()" *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span></span>
      <span class="spacer"></span>
      <div>
        <a *ngIf="(isHandset$ | async) === false" routerLink="/intro" routerLinkActive="active">Intro</a>
        <a *ngIf="(isHandset$ | async) === false" routerLink="/about" routerLinkActive="active">About</a>
        <a *ngIf="(isHandset$ | async) === false" routerLink="/services" routerLinkActive="active">Services</a>
        <a *ngIf="(isHandset$ | async) === false" routerLink="/contact" routerLinkActive="active">Contact</a>
      </div>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>

</mat-sidenav-container>
<app-footer></app-footer>
