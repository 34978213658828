<app-main-nav>

  <div class="contact-body">
    <div class="one">

    </div>
    <div class="two">
      <h1>Contact Me</h1>
      <h2>If you want to get started with AWS, cloud native applications, distributed systems or if you think you need my help with architecting
        on AWS or just fancy saying hey, then get in touch.
      </h2>


       <h2>You can mail me at:<a href="mailto:fazla@mdfazlarabby.com" class="email-lnk">fazla@mdfazlarabby.com </a></h2>



    </div>
    <div class="three"></div>
  </div>
</app-main-nav>
