<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" *ngIf="isHandset$ | async" fixedInViewport="false"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar color="primary">Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/intro">Intro</a>
      <a mat-list-item routerLink="/about">About</a>
      <a mat-list-item routerLink="/services">Services</a>
      <a mat-list-item routerLink="/contact">Contact</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>my-portfolio</span>
      <span class="spacer"></span>
      <a *ngIf="(isHandset$ | async) === false" routerLink="/intro" routerLinkActive="active">Intro</a>
      <a *ngIf="(isHandset$ | async) === false" routerLink="/about">About</a>
      <a *ngIf="(isHandset$ | async) === false" routerLink="/services">Services</a>
      <a *ngIf="(isHandset$ | async) === false" routerLink="/contact">Contact</a>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
